// Homepage.js
import React, { useState, useEffect } from 'react';
import './Homepage.css';
import { Link, useNavigate } from 'react-router-dom';

function Homepage() {
  useEffect(() => {
    // Set the initial viewport height as a CSS variable
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);
  return (
    <div id="homepage" className="homepage-section">
      <div className="main-container">
        <div className="image_shopper-container">
      <img src="Shopper_comp.png" alt="Image 2 Description" />
        </div>
        <div className="image_juxta-container">
      <img src="Juxtaposition.png" alt="Image 2 Description" />
      </div>
      <div className="image_building-container">
      <img src="building_pic.jpeg" alt="Image 1 Description" />
      </div>
      <div className="text-block-4">
       Ruben Einsmann
        </div>
      <div className="text-block-1">
        Was ist Kulturhaus:
        <br/><br/>
        Kultur Haus präsentiert wechselweise Kunstveranstaltungen in außergewöhnlichen und unkonventionellen Räumlichkeiten. Dies ist die Erste einer Reihe von Pop-Up-Ausstellungen.
        <br/><br/>
        Unser Ziel ist es, ein Netzwerk für junge KünstlerInnen zu erschaffen, in dem sie ihre Werke präsentieren und sich mit anderen Kreativen in Verbindung setzen können. Wir möchten die Förderung von jungen Talenten unterstützen und ihnen eine Möglichkeit geben, ihre kreativen Praktiken auszuleben.
        <br/><br/>
        Wir legen großen Wert auf den aktiven Austausch zwischen BesucherInnen und KünstlerInnen. Deshalb bieten wir bei unseren Events Workshops und Diskussionsrunden an, in denen Gedanken und Ideen Netzwerke entstehen sollen.
      </div>
      <div className="text-block-6">
       31.08 - 02.09
        </div>
      <div className="text-block-7">
       Leo Beddermann
      </div>
      <div className="text-block-8">
       "Shopper"
       <br/><br/>
       130 x 135cm
      </div>
      <div className="text-block-3">
       Was ist Joseph Konsum ?
        <br/><br/>
        </div>
        <div className="text-block-2">
        Joseph Konsum ist ein ehemaliges Kaufhaus im Herzen von Leipzig. Die Haus & Capital Unternehmensgruppe hat es sich gemeinsam mit den Bla° Architekten zur Aufgabe gemacht, das kultige Gebäude nach allen Denkmalschutz Vorgaben zu modernisieren und damit neu auferstehen zu lassen. Das Gebäude steht kurz vor der Fertigstellung, befindet sich derzeit aber noch im Zustand des Rohbaus. Dies bietet die ideale Atmosphäre, um Kunstwerke in einem außergewöhnlichen Rahmen zu präsentieren. Die Geschichte und der Charakter des Gebäudes laden zu einem unkonventionellen Austausch zwischen BesucherInnen und Künstler ein.
        </div>
        <div className="text-block-5">
       "Juxtaposition" 2020
        <br/><br/>
        195 x 165cm
        </div>
      <img src="dmc_logo-removebg-preview.png" alt="Image 3 Description" className="image-3-placeholder" />
        <Link to="/portfolio" className="button button-left hidden">OPEN PORTFOLIO</Link>
        <div className="fixed-bar">
    <img src="Logo.png" alt="Logo" className="logo" />
      </div>
        <div className="button-container">
          <Link to="/shop" className="button button-middle hidden">SHOP</Link>
          <Link to="/programme" className="button button-right hidden">PROGRAMME</Link>
        </div>
      </div>
      <a href="https://www.josephkonsum.de" target="_blank" rel="noopener noreferrer">
    <img src={"Josephkonsum.png"} alt="Joseph Konsum" className="josephkonsum-image" />
      </a>
    </div>
  );
}

export default Homepage;
