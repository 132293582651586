// SecondPage.js
import React from 'react';
import './Homepage_cont.css';
import './Homepage.css'

function Homepage_cont() {
  return (
    <div id="homepage_cont" className="homepage_cont-section">
        <div className="text_cont-block-3">
       Momo Bera
        </div>
    <div className="text_cont-block-1">
       YOUTH SANCTUARY
        </div>
        <div className="image_flights-container">
        <img src="Bad Flights.png" alt="Image 2 Description" />
        </div>
      <div className="image_idole-container">
        <img src="L'Idole.png" alt="Image 2 Description" />
        </div>
       <div className="text_cont-block-2">
       Konzept Ausstellung:
       <br/><br/>
       <br/><br/>
       Kultur Haus, ein frisch gegründetes Kollektiv für Kunst und Mode, präsentiert stolz ihre erste Ausstellung, "Youth Sanctuary". Diese Ausstellung erforscht die emotionalen und spirituellen Aspekte des Aufbaus einer neuen Gemeinschaft rund um die Jugendkultur und präsentiert dabei die Kernwerte der Marke: Bildung, Zusammenarbeit, Transparenz, Kreativität und Selbstausdruck.
       </div>
    <div className="text_cont-block-9">
       Leo Beddermann
      </div>
    <div className="text_cont-block-10">
        "Bad Flights"
       <br></br>
       200 x 200cm
      </div>
       <div className="text_cont-block-6">
       Die Vision dieser Ausstellung ist es, den Besuchern ein immersives Erlebnis zu bieten und jungen Künstlern eine Plattform zu geben, um ihr Talent und ihre Ideen zu präsentieren. 
       </div>
    <div className="text_cont-block-11">
       Ruben Einsmann
      </div>
    <div className="text_cont-block-12">
        "L'Idole"
       <br></br>
       2021 197 x 157cm
    </div>
       <div className="text_cont-block-7">
       Die geplante Veranstaltung soll eine kreative Begegnungsstätte für junge Kunstschaffende werden. Auf über 800 m2 Ausstellungsfläche werden 12 junge Talente ihre Arbeit vom 31. August bis zum 2. September 2023 präsentieren können.
       </div>
    <div className="text_cont-block-13">
       Svenja Jarisch
    </div>
    <div className="text_cont-block-14">
        "Zyklus I - IIII"
       <br></br>
       2023 25 x 36cm
    </div>
       <div className="text_cont-block-8">
       Unter Anderem zeigen Ruben Einsmann, Svenja Jarisch, Lukas Niedermeier, Anna Bochkova,  Jakob Nickels, Nadja Kracunovic, Momo Bera, Hanna Jo, Leo Beddermann, Lucie Freyenhagen, Marco Siciliano und Philipp Pohn ihre Werke.
        </div>
        <div className="text_cont-block-4">
       Aus der Reihe "faces of haunting"
       <br/><br/>
       2022 40x30cm
        </div>
        <div className="image_cont_2-container">
            <img src="Zyklus_1.png" alt="Description 1" />
            <img src="Zyklus_2.png" alt="Description 2" />
            <img src="Zyklus_3.png" alt="Description 3" />
            <img src="Zyklus_4.png" alt="Description 4" />
    </div>
        
        <div className="image_cont-container">
            <img src="SS1.png" alt="Description 1" />
            <img src="SS2.png" alt="Description 2" />
            <img src="SS3.png" alt="Description 3" />
            <img src="SS4.png" alt="Description 4" />
            <img src="SS5.png" alt="Description 5" />
            <img src="SS6.png" alt="Description 6" />
            <img src="SS7.png" alt="Description 7" />
            <img src="SS8.png" alt="Description 8" />
    </div>
    </div>
  );
}

export default Homepage_cont;
