// Programme.js
import React from 'react';
import './Programme.css'; 
function Programme() {
  return (
    <div className="programme-container">
    <img src="Logo.png" alt="Logo" className="logo-programme" />
      <h1>MORE COMING SOON</h1>
    </div>
  );
}

export default Programme;
