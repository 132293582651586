// Portfolio.js
import React from 'react';
import './Portfolio.css'; 

function Portfolio() {
  return (
    <div className="portfolio-container">
        <img src="Logo.png" alt="Logo" className="logo-portfolio" />
      <h1>MORE COMING SOON</h1>
    </div>
  );
}

export default Portfolio;
