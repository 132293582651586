// Shop.js
import React from 'react';
import './Shop.css'; 
function Shop() {
  return (
    <div className="shop-container">
        <img src="Logo.png" alt="Logo" className="logo-shop" />
      <h1>MORE COMING SOON</h1>
    </div>
  );
}

export default Shop;
