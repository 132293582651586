import React from 'react';
import './App.css';
import Homepage from './Homepage';
import Portfolio from './Portfolio';  // import the new pages
import Shop from './Shop';
import Programme from './Programme';
import LandingPage from './LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Mainpage from './Mainpage';

function App() {
  return (
    <Router>
        {/* Pages */}
        <Routes>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/programme" element={<Programme />} />
          <Route path="/home" element={<Mainpage />} />
          <Route path="/" element={<LandingPage />} /> // Landing page is now the default route
        </Routes>
    </Router>
  );
  }

export default App;
