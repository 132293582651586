// MainPage.js
import React from 'react';
import Homepage from './Homepage';
import Homepage_cont from './Homepage_cont';

function Mainpage() {
  return (
    <div>
      <Homepage />
      <Homepage_cont />
    </div>
  );
}

export default Mainpage;
