// LandingPage.js
import React from 'react';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
    const navigate = useNavigate();


    return (
        <div className="landing-page">
                <img src="/Entrance_Screen.png" alt="Landing Page Image" className="landing-image" />
            </div>
    );
}

export default LandingPage;
